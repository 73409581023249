import React, { useEffect, useRef } from 'react';
import CatergoryCard from './CatergoryCard';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../Common/BreadCrumb/BreadCrumb';
import TopicCard from './TopicCard';
import InfiniteScrollling from '../../Pagination/InfiniteScrollling';
import { CenterLoadingBar, LoadingBar } from '../../loader/loader';
import { useTranslation } from 'react-i18next';
import { Pagination } from 'antd';
const TopicCategoryDetail = ({
  learndeckStats,
  learnDeckLists,
  count,
  setlearnDeckPage,
  learnDeckPage,
  deckLoading,
  setFlashCardId,
  handleExam,
  openExamPage,
  topicId,
  quizStatus,
  externalVideos,
  totalRecords,
  pageNum,
  handlePageChange,
  isVideoFetching,
}) => {
  const { t } = useTranslation();
  const iframeRef = useRef(null);

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    if (iframeRef.current) {
      iframeRef.current.contentWindow?.addEventListener(
        'contextmenu',
        handleContextMenu
      );
    }

    return () => {
      if (iframeRef.current) {
        iframeRef.current.contentWindow?.removeEventListener(
          'contextmenu',
          handleContextMenu
        );
      }
    };
  }, []);

  return (
    <>
      <div className='container category_detail_wrapper '>
        {learndeckStats?.category ? (
          <BreadCrumb
            title='Topic detail page'
            id={5}
            params={{
              category: learndeckStats?.category,
              subject: learndeckStats?.subject,
              topic: topicId,
            }}
          />
        ) : (
          <p className='list_bread'></p>
        )}

        <div className='row detail_row'>
          <div
            className={`col-4 ${
              quizStatus === true && learndeckStats?.goalExist === false
                ? 'col-xl-3 col-lg-4 col-12  detail_left_col detail_left_col-h'
                : learndeckStats?.goalExist === false
                ? 'col-xl-3 col-lg-4 col-12  detail_left_col '
                : learndeckStats?.goalExist === true && quizStatus === true
                ? 'col-xl-3 col-lg-4 col-12  detail_left_col detail_left_col-goals'
                : ''
            }`}
          >
            {deckLoading ? (
              <CenterLoadingBar />
            ) : (
              <CatergoryCard
                data={{
                  progress: learndeckStats?.progressPercent,
                  title: learndeckStats?.title,
                  description: learndeckStats?.description,
                  count: learndeckStats?.decksCount,
                  level: learndeckStats?.learningLevel,
                  colorr: learndeckStats?.categoryColor,
                  levelStatus: learndeckStats?.levelStatus,
                  goalExist: learndeckStats?.goalExist,
                  goalObj: learndeckStats?.goalObj,
                }}
                detail={true}
                level={true}
                button={true}
                handleExam={handleExam}
                openExamPage={openExamPage}
                decks={true}
                temp={true}
                levelStatus={true}
                quizStatus={quizStatus}
              />
            )}
          </div>

          <div className='col-xl-8 col-lg-8 col-12 detail_right_col'>
            <div className='subject_description' title={learndeckStats?.title}>
              {t('LEARN.DECKS_UNDER')}
              {learndeckStats?.title?.length > 25
                ? `${learndeckStats?.title?.slice(0, 25)}...`
                : learndeckStats?.title}{' '}
              ({count ?? 0})
            </div>

            {deckLoading && learnDeckPage === 0 ? (
              <CenterLoadingBar />
            ) : (
              <InfiniteScrollling
                dataLength={learnDeckLists?.length}
                next={() => setlearnDeckPage(learnDeckPage + 1)}
                hasMore={learnDeckLists?.length < count}
                loader={<LoadingBar />}
              >
                <div className='row'>
                  {learnDeckLists?.length
                    ? learnDeckLists.map((data, i) => {
                        return (
                          <div
                            className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-3'
                            key={i}
                          >
                            <Link to={`/learn/flash-card?deck=${data?._id}`}>
                              <TopicCard data={data} />
                            </Link>
                          </div>
                        );
                      })
                    : ''}
                </div>
              </InfiniteScrollling>
            )}
          </div>
          <div
            className='subject_description'
            style={{
              fontWeight: 'bold',
              color: 'black',
              fontSize: '24px',
              marginTop: '20px',
              marginLeft: '0.5rem',
              marginBottom: '0.5rem',
            }}
          >
            {t('COMMON.VIDEO')}
          </div>
          {isVideoFetching ? (
            <CenterLoadingBar />
          ) : externalVideos.length > 0 ? (
            <>
              {externalVideos.map((video, index) => (
                <div
                  key={index}
                  style={{
                    width: 300,
                    height: 280,
                    overflow: 'hidden',
                    cursor: 'pointer',
                    marginBottom: '20px',
                    marginLeft: '10px',
                    marginTop: '10px',
                    borderRadius: '20px',
                  }}
                >
                  <iframe
                    ref={iframeRef}
                    src={video.url}
                    loading='lazy'
                    allow='accelerometer; gyroscope; encrypted-media; picture-in-picture;'
                    allowFullScreen
                    style={{
                      width: '100%',
                      height: '100%',
                      border: 'none',
                      borderRadius: '20px',
                    }}
                    onContextMenu={(e) => e.preventDefault()} // Disable right-click
                  ></iframe>
                </div>
              ))}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '20px',
                }}
              >
                <Pagination
                  current={pageNum}
                  onChange={handlePageChange}
                  total={totalRecords}
                  pageSize={10}
                />
              </div>
            </>
          ) : (
            <div className='subject_description'>No Videos found</div>
          )}
        </div>
      </div>
    </>
  );
};

export default TopicCategoryDetail;
