import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'swiper/css/bundle';
import 'react-circular-progressbar/dist/styles.css';
import 'react-toastify/dist/ReactToastify.css';

// Redux Imports
import { Store } from './state';
import setThemeVariables from './components/theme/setThemeVariables'; // Update the path accordingly

// Components Imports
import NavigationRoutes from './routes';

// Styles Imports
import './styles/styles.scss';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { useSelector } from 'react-redux';

const App = () => {
  setThemeVariables()
  const theme = useSelector((state) => state.manageTheme?.theme?.icoIcon);

  const changeFavicon = (faviconURL) => {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = `${faviconURL}?v=${new Date().getTime()}`; 

    document.getElementsByTagName('head')[0].appendChild(link);
  };

  useEffect(() => {
    if (theme) {
      changeFavicon(theme);
    }
  }, [theme]);

  useEffect(() => {
    console.log = function () { };
  }, []);

  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <NavigationRoutes />
        <ToastContainer
          className='ml-toast_container'
          position='top-right'
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          closeButton={true}
          draggable
          theme='dark'
        />
      </I18nextProvider>
    </BrowserRouter>
  );
};

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={Store}>
    <App />
  </Provider>
);