import { useEffect } from "react";
import { useSelector } from "react-redux";

const SetButtonColor = () => {
  const theme = useSelector((state) => state.manageTheme.theme);

  useEffect(() => {
    if (theme) {
      if (theme?.loginBackgroundImage) {
        document.documentElement.style.setProperty(
          "--login-bg-image",
           `url(${theme?.loginBackgroundImage})`
        );
      }

      document.documentElement.style.setProperty(
        "--bg-color",
        theme?.PrimaryFont 
      );

      //font
      document.documentElement.style.setProperty(
        "--PrimaryFont-color",
        theme?.PrimaryFont
      );

      document.documentElement.style.setProperty(
        "--button-color",
        theme?.containedButtonBackground
      );
      document.documentElement.style.setProperty(
        "--containedButtonBackgroundActive-color",
        theme?.containedButtonBackgroundActive
      );
      document.documentElement.style.setProperty(
        "--button-containedButtonBackgroundHover-color",
        theme?.containedButtonBackgroundHover
      );

      document.documentElement.style.setProperty(
        "--outlinedButtonBackground-color",
        theme?.outlinedButtonBackground
      );

      document.documentElement.style.setProperty(
        "--selectedCheckbox-color",
        theme?.selectedCheckbox
      );

      document.documentElement.style.setProperty(
        "--loader-color",
        theme?.loader
      );
      document.documentElement.style.setProperty(
        "--inActiveMenuIcons-color",
        theme?.inActiveMenuIcons
      );
      document.documentElement.style.setProperty(
        "--activeMenuIcons-color",
        theme?.activeMenuIcons
      );
    }
  }, [theme]);

  return null; // This component doesn't render anything
};

export default SetButtonColor;
